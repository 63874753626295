$(document).ready(function() {

  // mobile menu toggler
  $('#toggle').click(function() {
    $(this).toggleClass('active');
    $('#overlay').toggleClass('open');
  });

  // video popup
  $('.popup-video').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });

  // popup init
	$('.open-popup').magnificPopup({
	  type:'inline',
	  midClick: true,
		mainClass: 'mfp-with-fade'
	});

  // banner slider init
  $('.banner-slider').slick({
	    autoplay: true,
			arrows: false
	  });

  // logo slider init
	$('.sponsor-logos__slider').slick({
    autoplay: true,
		arrows: false,
		fade: true,
	  cssEase: 'linear'
  });

  // speaker slider init
	$('.speaker-slider').slick({
    autoplay: true,
		arrows: false,
    speed: 250,
    slidesToShow: 5,
	  cssEase: 'ease',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    prevArrow: '<button type="button" class="speaker-slider__button speaker-slider__button--prev">◀</button>',
    nextArrow: '<button type="button" class="speaker-slider__button speaker-slider__button--next">▶</button>'
  });

});
