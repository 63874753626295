$(document).ready(function(){
    $('.timeline').slick({
        infinite: true,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 750,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ],
        slidesToShow: 4,
        prevArrow: '<button type="button" class="timeline__button timeline__button--prev"><img src="/images/ui/timeline-icon-prev.svg"></button>',
        nextArrow: '<button type="button" class="timeline__button timeline__button--next"><img src="/images/ui/timeline-icon-next.svg"></button>'
    });

    // equalise the slide heights
    $('.timeline').on('setPosition', function () {
      $(this).find('.slick-slide').height('auto');
      var slickTrack = $(this).find('.slick-track');
      var slickTrackHeight = $(slickTrack).height();
      $(this).find('.timeline__item').css('height', slickTrackHeight + 'px');
    });
});